import React from "react";
import Navigation from "./Navigation";
import Logo from "../Logo";
import { toggleMenu } from "util/toggle-menu";

import ThemeToggle from "../ThemeToggle";

const Menu = () => {
  return (
    <div className="menu">
      <div className="menu__inner">
        <button onClick={toggleMenu} type="button" className="menu__close">
          <i className="close-btn" />
        </button>
        <div className="menu__logo">
          <Logo />
        </div>

        <div className="menu__content container">
          <Navigation id="menu-nav">
            <li className="nav__item">
              <ThemeToggle />
            </li>
          </Navigation>
        </div>
      </div>
    </div>
  );
};

export default Menu;
