import React from "react";
import { useLocation } from "react-router-dom";

import IconTelegramBlue from "assets/images/telegram-blue.svg";
import ImageBackground from "assets/images/payment-vk-bg.jpg";
import ImageBackgroundWebp from "assets/images/payment-vk-bg.webp";
import ImageBackgroundDesktopWebp from "assets/images/payment-vk-bg-desktop.webp";
import ImageBackgroundDesktop from "assets/images/payment-vk-bg-desktop.jpg";

import "assets/styles/payment.scss";
import { useI18n } from "i18n/use-i18n";

function PaymentTelegram(props) {
  const { translate } = useI18n();

  const location = useLocation();
  const period_purchase_page =
    props.period_purchase_page ?? location.state?.period_purchase_page;

  console.log("period_purchase_page", period_purchase_page);

  return (
    <div className="payment">
      <div className="payment__heading">
        <picture className="payment__bg">
          <source
            type="image/webp"
            media="(max-width: 600px)"
            srcSet={ImageBackgroundWebp}
          />
          <source
            type="image/jpg"
            media="(max-width: 600px)"
            srcSet={ImageBackground}
          />
          <source
            type="image/webp"
            media="(min-width: 599px)"
            srcSet={ImageBackgroundDesktopWebp}
          />
          <source
            type="image/jpg"
            media="(min-width: 599px)"
            srcSet={ImageBackgroundDesktop}
          />
          <img src={ImageBackground} alt="" />
        </picture>
        <div className="container">
          <h1 className="title">{translate("paymentTelegram_title")}</h1>

          {period_purchase_page && (
            <h2 className="payment__period">
              {translate(period_purchase_page)}
            </h2>
          )}

          <p
            dangerouslySetInnerHTML={{
              __html: translate("paymentTelegram_text1"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: translate("paymentTelegram_text2"),
            }}
          />

          <a
            className="btn btn--lg btn--no-border"
            href="https://t.me/anonrubot"
          >
            <img width={18} height={18} src={IconTelegramBlue} alt="Telegram" />
            Перейти в бота
          </a>
        </div>
      </div>

      <div className="payment__footer">
        <div className="container">
          <p
            dangerouslySetInnerHTML={{
              __html: translate("paymentTelegram_footer"),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentTelegram;
