import React from "react";
import FAQItem from "components/FAQItem";
import { NavLink } from "react-router-dom";

import { FAQListCommon } from "constants/FAQ";
import { useI18n } from "i18n/use-i18n";

function HomeFAQ() {
  const { translate } = useI18n();
  return (
    <div className="container">
      <div className="home-faq">
        <h2 className="title title--xl">{translate("home_faq_title")}</h2>
        <div className="home-faq__list">
          {FAQListCommon.map(({ title, text }, index) => (
            <FAQItem
              key={index}
              title={translate(title)}
              text={translate(text)}
              index={index}
            />
          ))}
        </div>
        <NavLink className="btn btn--lg" to="/faq">
          {translate("home_faq_link")}
        </NavLink>
      </div>
    </div>
  );
}

export default HomeFAQ;
