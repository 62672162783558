import { translate } from "./translate";
import { useEffect, useState } from "react";
import { isUserFrom } from "../util/get-user-country";
import { isAnonRuBot } from "../util/is-anonrubot";

export const useI18n = () => {
  // Determine initial language by project name
  const initialLanguage = isAnonRuBot() ? "ru" : "en";
  const [language, setLanguage] = useState(initialLanguage);
  useEffect(() => {
    determineUserLanguage().then(setLanguage);
  }, []);
  return {
    // bind language to translate function
    translate: translate.bind(null, language),
    language,
  };
};

const determineUserLanguage = async () => {
  const isChatBot = !isAnonRuBot();
  // If user is from Indonesia and use chatBot then return "id" language
  if (isChatBot && (await isUserFrom("ID"))) {
    return "id";
  }
  return isChatBot ? "en" : "ru";
};
