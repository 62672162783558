import React from "react";

import "assets/styles/components/promo-bot.scss";
import PicBackground from "assets/images/promo-bot/banner.jpg";
import PicBackgroundWebp from "assets/images/promo-bot/banner.webp";
import PicBackgroundDesktop from "assets/images/promo-bot/banner-desktop.jpg";
import PicBackgroundDesktopWebp from "assets/images/promo-bot/banner-desktop.webp";
import PicCard from "assets/images/promo-bot/master-card.svg";
import PicVisa from "assets/images/promo-bot/visa.svg";
import PicMir from "assets/images/promo-bot/mir.svg";
import PicVuesax from "assets/images/promo-bot/vuesax.svg";
import PicUsdt from "assets/images/promo-bot/usdt.svg";
import PicEtherium from "assets/images/promo-bot/etherium.svg";
import PicBitcoin from "assets/images/promo-bot/bitcoin.svg";
import { useBotUrl } from "../../hooks/use-bot-url";

const paymentList = [
  {
    img: PicCard,
    alt: "Master Card",
  },
  {
    img: PicVisa,
    alt: "Visa",
  },
  {
    img: PicMir,
    alt: "Мир",
  },
  {
    img: PicVuesax,
    alt: "Vuesax",
  },
  {
    img: PicUsdt,
    alt: "Tether",
  },
  {
    img: PicEtherium,
    alt: "Ethereum",
  },
  {
    img: PicBitcoin,
    alt: "Bitcoin",
  },
];

function PromoBot() {
  const botUrl = useBotUrl();
  return (
    <a href={botUrl} className="promo-bot">
      <picture className="promo-bot__bg">
        <source
          type="image/webp"
          media="(max-width: 600px)"
          srcSet={PicBackgroundWebp}
        />
        <source
          type="image/jpg"
          media="(max-width: 600px)"
          srcSet={PicBackground}
        />
        <source
          type="image/webp"
          media="(min-width: 599px)"
          srcSet={PicBackgroundDesktopWebp}
        />
        <source
          type="image/jpg"
          media="(min-width: 599px)"
          srcSet={PicBackgroundDesktop}
        />
        <img src={PicBackground} alt="" />
      </picture>
      <div className="container">
        <div className="promo-bot__content">
          <h2 className="title title--lg">
            Купи премиум для Анонимного чата Телеграма
          </h2>
          <div className="promo-bot__list">
            {paymentList.map(({ alt, img }, index) => (
              <div key={index} className="promo-bot__icon">
                <img src={img} alt={alt} />
              </div>
            ))}
          </div>
          <div className="btn btn--lg btn--no-border">Купить сейчас</div>
        </div>
      </div>
    </a>
  );
}
export default PromoBot;
