import React from "react";
import PicVK from "assets/images/bots/vk-heading.png";
import BotsGallery from "components/botsSwiper";
import ImgVk from "assets/images/vk-white.svg";

import "assets/styles/bots.scss";

function BotVk() {
  return (
    <div className="bots section">
      <div className="bots__pic-wrap">
        <picture className="bots__pic">
          <img width={166} height={250} src={PicVK} alt="Vk Chat" />
        </picture>
      </div>
      <div className="container">
        <h1 className="title title--xl">Анонимный Чат ВКонтакте</h1>
        <p className="bots__descripton">
          Первый и самый популярный анонимный чат Телеграма
        </p>
      </div>
      <BotsGallery />
      <a href="https://vk.com/vkchat" className="btn btn--lg">
        <img width={18} height={18} src={ImgVk} alt="VK" />
        Перейти в бота
      </a>
    </div>
  );
}

export default BotVk;
