import React from "react";
import { useColorScheme } from "../hooks/useColorScheme";

import "assets/styles/components/toggle.scss";

function ThemeToggle() {
  const { isDark, setIsDark } = useColorScheme();
  return (
    <div className="theme-toggle">
      Темная тема
      <div className="toggle">
        <input
          type="checkbox"
          id="toggle"
          className="toggle__input"
          checked={isDark}
          onChange={({ target }) => setIsDark(target.checked)}
        />
        <label htmlFor="toggle" className="toggle__label"></label>
      </div>
    </div>
  );
}

export default ThemeToggle;
