import Navigation from "./Navigation";
import Logo from "../Logo";
import ImageArrow from "../../assets/images/arrow.svg";
import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__logo">
        <Logo desktopOnly={true} />
      </div>
      <Navigation id="footer-nav">
        <li className="nav__item">
          <a
            className="nav__title"
            href="/moneyback.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Политика возвратов
            <img width={16} height={16} src={ImageArrow} alt="show" />
          </a>
        </li>
        <li className="nav__item">
          <a
            className="nav__title"
            href="/tos.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Правила использования сервисов
            <img width={16} height={16} src={ImageArrow} alt="show" />
          </a>
        </li>
      </Navigation>
      <div className="footer__copyright">ИП Полетаев И.Э.</div>
    </footer>
  );
};

export default Footer;
