import React from "react";

import "assets/styles/contacts.scss";

import IconMail from "assets/images/mail.svg";
import IconPhone from "assets/images/phone.svg";
import IconClose from "assets/images/close.svg";
import IconFile from "assets/images/file.svg";
import { useI18n } from "../i18n/use-i18n";

const contactsList = [
  {
    title: "contacts_items_title1",
    text: "contacts_items_text1",
    icon: IconMail,
  },
  {
    title: "contacts_items_title2",
    text: "contacts_items_text2",
    icon: IconPhone,
  },
  {
    title: "contacts_items_title3",
    text: "contacts_items_text3",
    icon: IconClose,
  },
  {
    title: "contacts_items_title4",
    text: "contacts_items_text4",
    icon: IconFile,
  },
];

function Contacts() {
  const { translate } = useI18n();
  return (
    <div className="contacts section">
      <div className="container">
        <h1 className="title title--xl">{translate("contacts_title")}</h1>

        {contactsList.map(({ title, text, icon }, index) => (
          <div key={index} className="contacts-item card card--gradient-sm">
            <h2 className="title title--sm">{translate(title)}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: translate(text),
              }}
            />
            <div className="contacts-item__icon">
              <img width={12} height={12} src={icon} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Contacts;
