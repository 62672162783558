import React from "react";
import IconArrow from "assets/images/arrow.svg";

const FAQItem = ({ title, text, index }) => {
  return (
    <div className="faq-item card expand-content">
      <input id={`faq-item-${index}`} type="checkbox" />
      <label htmlFor={`faq-item-${index}`}>
        <h3 className="title title--sm">{title}</h3>
        <i className="btn faq__button">
          <img src={IconArrow} width={14} height={14} alt="Show" />
        </i>
      </label>
      <div
        className="expand-content__inner"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default FAQItem;
