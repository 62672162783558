import React, { useState } from "react";
import { useI18n } from "../i18n/use-i18n";

import "assets/styles/cancel-subscription.scss";
import EnvelopeIcon from "assets/images/envelope.svg";

function CancelSubscription() {
  const { translate } = useI18n();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const urlParams = new URLSearchParams({ email });
      await fetch(`/api/send-link?${urlParams.toString()}`);
      setEmail("");
      setMessage(translate("cancel_subscription_success_message"));
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setMessage(translate("cancel_subscription_error_message"));
    }
  };

  return (
    <div className="cancel-subscription section">
      <div className="container">
        <div className="cancel-subscription__content">
          <div className="cancel-subscription__icon">
            <img src={EnvelopeIcon} alt="Envelope" />
          </div>
          <h1 className="title title--xl">
            {translate("cancel_subscription_title")}
          </h1>
          <p className="cancel-subscription__description">
            {translate("cancel_subscription_description")}
          </p>
          <form onSubmit={handleSubmit} className="cancel-subscription__form">
            <div className="input-group">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={translate("cancel_subscription_email_placeholder")}
                required
              />
              <button type="submit" className="btn btn--primary">
                {translate("cancel_subscription_button")}
              </button>
            </div>
          </form>
          {message && (
            <div className="cancel-subscription__message">{message}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CancelSubscription;
