export const NavigationList = [
  {
    label: "navigation_home",
    link: "/",
  },
  {
    label: "navigation_faq",
    link: "/faq",
  },
  {
    title: "navigation_bots",
    submenus: [
      {
        label: "navigation_telegram",
        link: "/bot/telegram",
      },
      {
        label: "navigation_vk",
        link: "/bot/vk",
      },
    ],
  },
  {
    title: "navigation_subscriptions",
    submenus: [
      {
        label: "navigation_telegram",
        link: "/subscriptions/telegram",
      },
      {
        label: "navigation_vk",
        link: "/subscriptions/vk",
      },
    ],
  },
  {
    label: "navigation_contacts",
    link: "/contacts",
  },
];
