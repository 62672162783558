import React from "react";
import PicChat from "assets/images/bots/telegram-heading.png";
import BotsGallery from "components/botsSwiper";
import ImgTelegram from "assets/images/telegram.svg";

import "assets/styles/bots.scss";

function BotTelegram() {
  return (
    <div className="bots section">
      <div className="bots__pic-wrap">
        <picture className="bots__pic">
          <img width={166} height={250} src={PicChat} alt="Telegram Chat" />
        </picture>
      </div>
      <div className="container">
        <h1 className="title title--xl">Анонимный Чат Телеграма</h1>
        <p className="bots__descripton">
          Первый и самый популярный анонимный чат Телеграма
        </p>
      </div>
      <BotsGallery />
      <a href="https://t.me/anonrubot" className="btn btn--lg">
        <img width={18} height={18} src={ImgTelegram} alt="Telegram" />
        Перейти в бота
      </a>
    </div>
  );
}

export default BotTelegram;
