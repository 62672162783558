import { useColorScheme } from "./useColorScheme";
import { isAnonRuBot } from "../util/is-anonrubot";

export const useBotUrl = () => {
  const { isDark } = useColorScheme();

  return isAnonRuBot()
    ? `https://bots.anonchat.io?darkTheme=${isDark}`
    : `https://premium.anonchatbot.com?darkTheme=${isDark}`;
};
