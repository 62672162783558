import { fetchWithTimeout } from "./fetch-with-timeout";

// Cache the promise so we only call the API once
let userCountryPromise = null;

export const getUserCountry = () => {
  if (userCountryPromise) {
    return userCountryPromise;
  }

  userCountryPromise = (async () => {
    try {
      const response = await fetchWithTimeout(
        "https://geoapi.stivisto.com/analyze-ip?ip=mine"
      );
      const data = await response.json();
      return data?.result?.country_iso_code ?? "US";
    } catch (e) {
      console.log(e);
      return "US";
    }
  })();

  return userCountryPromise;
};

export const isUserFrom = async (userCountry) => {
  const country = await getUserCountry();
  return country.toUpperCase() === userCountry.toUpperCase();
};
